import React, { useContext, useEffect, useRef } from "react";
import "./Values.scss";
import ValueBox from "./ValueBox";
import { LangContext } from "../../../context/LanguageProvider";
import { translations } from "./translate";

const Values = () => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];
  const sectionRef = useRef(null);

  useEffect(() => {
    const sectionRefCurrent = sectionRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const children = entry.target.children;
          const childrenArray = Array.from(children);
          childrenArray.forEach((child, index) => {
            setTimeout(() => {
              child.classList.add("animate__pulse", "animate__repeat-2");
            }, index * 100);
          });
        } else {
          const children = entry.target.children;
          const childrenArray = Array.from(children);
          childrenArray.forEach((child) => {
            child.classList.remove("animate__pulse", "animate__repeat-2");
          });
        }
      });
    });

    if (sectionRefCurrent) {
      observer.observe(sectionRefCurrent);
    }

    return () => {
      if (sectionRefCurrent) {
        observer.unobserve(sectionRefCurrent);
        const children = sectionRefCurrent.children;
        const childrenArray = Array.from(children);
        childrenArray.forEach((child) => {
          child.classList.remove("animate__pulse", "animate__repeat-2");
        });
      }
    };
  }, [sectionRef]);

  return (
    <div className="values">
      <div className="header">
        <div className="title">{translatedData.title}</div>
        <div className="desc">{translatedData.desc} </div>
      </div>
      <div className="grid">
        <div className="grid-container" ref={sectionRef}>
          {translatedData.values.map((item, index) => (
            <ValueBox
              key={index}
              icon={item.icon}
              desc={item.desc}
              title={item.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Values;
