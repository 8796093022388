import { createSlice } from '@reduxjs/toolkit';
import { GetServicesAction } from '../actions/ServiceAction';
export const serviceReducer = createSlice({
    name: "Service Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        services: [],
    },
    reducers: {
        servicesUnmount: (state) => {
            state.services = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetServicesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetServicesAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.services = payload.data.services;
                state.totalItems = payload.data.totalItems;
                state.currentPage = payload.data.currentPage;
            })
            .addCase(GetServicesAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default serviceReducer.reducer;
export const { servicesUnmount } = serviceReducer.actions;
