import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetServicesAPI } from "../Api";
import { errorMessage } from "../constant";
import axios from "axios";

export const GetServicesAction = createAsyncThunk("user/services/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(GetServicesAPI + info.page + "&size=" + info.size);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});