export const translations = {
  en: {
    images: [
      {
        title: "Elevate Your Product Visibility",
        desc: "Enhance product visibility with our collaborative team, presenting high-quality solutions for global promotion",
        image: "/1.jpg",
      },
      {
        title: "Revolutionize Your Communication",
        desc: "Transform communication strategies with our expert blend of technical finesse and captivating design",
        image: "/2.jpg",
      },
      {
        title: "Optimize Workflows with Mobile Solutions",
        desc: "Maximize productivity through tailored mobile applications, optimizing work processes",
        image: "/3.jpg",
      },
      {
        title: "Crafting Excellence for International Markets",
        desc: "Deliver excellence for global markets through our fusion of technical precision and captivating design",
        image: "/4.jpg",
      },
    ]
  },
  ar: {
    images: [
      {
        title: "رفع رؤية منتجك",
        desc: "قم بتعزيز رؤية المنتج باستخدام فريقنا التعاوني، الذي يقدم حلولًا عالية الجودة للترويج العالمي",
        image: "/1.jpg",
      },
      {
        title: "ثورة في اتصالاتك",
        desc: "قم بتحويل استراتيجيات الاتصال باستخدام مزيج خبير من الدقة الفنية والتصميم الجذاب",
        image: "/2.jpg",
      },
      {
        title: "تحسين سير العمل باستخدام الحلول المحمولة",
        desc: "زيادة الإنتاجية من خلال تطبيقات الجوال المصممة خصيصًا، وتحسين عمليات العمل",
        image: "/3.jpg",
      },
      {
        title: "صياغة التميز للأسواق الدولية",
        desc: "تقديم التميز للأسواق العالمية من خلال دمجنا للدقة الفنية والتصميم الجذاب",
        image: "/4.jpg",
      },
    ]

  }
};
