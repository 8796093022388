import { createSlice } from '@reduxjs/toolkit';
import { AddContactAction, AddRequestAction, } from '../actions/ContactActions';
export const contactReducer = createSlice({
    name: "Contact Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        contacts: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(AddContactAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddContactAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddContactAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});
export default contactReducer.reducer;
