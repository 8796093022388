export const translations = {
  en: {
    contactUs: "Contact Us",
    fullNamePlaceholder: "Full Name",
    emailPlaceholder: "Email",
    descriptionPlaceholder: "Write something about us...",
    sendButtonLabel: "Send"
  },
  ar: {
    contactUs: "اتصل بنا",
    fullNamePlaceholder: "الاسم الكامل",
    emailPlaceholder: "البريد الإلكتروني",
    descriptionPlaceholder: "اكتب شيئًا عنا...",
    sendButtonLabel: "إرسال"
  }
};
