import React from "react";
import "./ServiceCard.scss";
const ServiceCard = ({ title, desc, image }) => {
  return (
    <div className="service-card animate__animated animate__fadeIn transparent">
      <img src={image} alt={`service ${title}`} />
      <div className="title">{title}</div>
      <div className="desc">
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
