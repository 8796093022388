import React from "react";
import Values from "./values/Values";
import Landing from "./landing/Landing";
import { useSelector } from "react-redux";
import LoadingFS from "../../components/loading/LoadingFS";
import "./Home.scss";

const Home = () => {
  const { loading } = useSelector((state) => state.aboutUs);

  return (
    <div className="home">
      {loading && <LoadingFS />}
      <Landing />
      <div className="overlay">
        <div className="container transparent">
          <Values />
        </div>
      </div>
    </div>
  );
};

export default Home;
