import React, { useContext } from "react";
import { Carousel } from "primereact/carousel";
import "./Slider.scss";
import { translations } from "./translate";
import { LangContext } from "../../context/LanguageProvider";

const Slider = () => {
  const { lang } = useContext(LangContext);
  const { images } = translations[lang];

  const imageTemplate = (item) => {
    return (
      <div className="image-container">
        <img src={item.image} alt="slider phrase" className="carousel-image" />
        <div className="middle-paragraph">
          <p className="header">{item.title}</p>
          <p className="paragraph">{item.desc}</p>
        </div>
      </div>
    );
  };
  // const animateHandler = (e) => {
    // const element = document.querySelector(".slider");
    // element.classList.add("animate__animated", "animate__pulse");
    // // element.classList.add("animate__animated", "animate__bounceInRight");
    // element.addEventListener("animationend", () => {
    //   element.classList.remove("animate__pulse");
    // });
  // };

  return (
    <div className="slider">
      <Carousel
        numVisible={1}
        numScroll={1}
        showIndicators={false}
        showNavigators={false}
        value={images}
        itemTemplate={imageTemplate}
        autoplayInterval={2000}
        circular
      />
    </div>
  );
};

export default Slider;
