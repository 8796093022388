import React, { useContext, useEffect } from "react";
import ServiceCard from "../../components/service-card/ServiceCard";
import { GetServicesAction } from "../../redux/actions/ServiceAction";
import { useDispatch, useSelector } from "react-redux";
import LoadingFS from "../../components/loading/LoadingFS";
import { imageURL } from "../../redux/Api";
import "./Services.scss";
import { LangContext } from "../../context/LanguageProvider";

const Services = () => {
  const { loading, services } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const { lang } = useContext(LangContext);

  useEffect(() => {
    dispatch(GetServicesAction({ page: 1, size: 100 }));
  }, [dispatch]);
  return (
    <div className="services">
      {loading && <LoadingFS />}
      <div className="grid">
        {services.map((item, index) => {
          return (
            <ServiceCard
              image={imageURL + item.imagePath}
              key={index}
              title={lang === "en" ? item.title_en : item.title_ar}
              desc={lang === "en" ? item.description_en : item.description_ar}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Services;
