import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorMessage } from "../constant";
import { AddContactAPI, PostRequestAPI } from "../Api";
import axios from "axios";

export const AddContactAction = createAsyncThunk("user/contacts/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.post(AddContactAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddRequestAction = createAsyncThunk("user/request/add", async (info, { rejectWithValue }) => {
    try {
        if (info.serviceId) {
            let { data } = await axios.post(PostRequestAPI + "/" + info.serviceId, info.obj);
            return data;
        }
        else {
            let { data } = await axios.post(PostRequestAPI, info.obj);
            return data;
        }
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});