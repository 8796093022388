import React from "react";
import "./ProjectCard.scss";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const ProjectCard = ({ title, desc, image, link }) => {
  return (
    <div className="project-card animate__animated animate__fadeIn transparent">
      <img src={image} alt={`service ${title}`} />
      <div className="content">
        <div className="title">{title}</div>
        <div className="desc"></div>
        <p>{desc}</p>
      </div>
      <Link to={link} target="_blank" rel="noopener noreferrer">
        <Button
          icon="pi pi-external-link"
          rounded
          aria-label="Filter"
          severity="info"
        />
      </Link>
    </div>
  );
};

export default ProjectCard;
