import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LangContext } from "../../context/LanguageProvider";
import { translations } from "./translate";
import "./Navbar.scss";

const Navbar = () => {
  const location = useLocation();
  const [navItems, setNavItems] = useState([]);
  const { toggle, lang } = useContext(LangContext);
  const [place, setPlace] = useState("");

  useEffect(() => {
    const place = location.pathname.slice(1);
    localStorage.setItem("tab", place);
    if (place === "") {
      setPlace("main");
    } else {
      setPlace(place);
    }

    const handleScroll = () => {
      const navbar = document.getElementsByClassName("navbar")[0];
      if (window.scrollY > 100) {
        navbar.classList.add("scrolled");
      } else if (window.scrollY === 0) {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  useEffect(() => {
    setNavItems(translations[lang].nav);
  }, [lang]);

  return (
    <div className="navbar">
      <div className="menu-bar">
        <img src={"/logo.png"} alt="mudar-logo"></img>
        <ul className="list">
          <Link>
            <li className="toggler" onClick={toggle}>
              {navItems[3]}
            </li>
          </Link>
          <Link to="main" onClick={() => setPlace("main")}>
            <li className={`item ${place === "main" ? "visited" : ""}`}>
              {navItems[0]}
            </li>
          </Link>
          <Link to="services" onClick={() => setPlace("services")}>
            <li className={`item ${place === "services" ? "visited" : ""}`}>
              {navItems[1]}
            </li>
          </Link>
          <Link to="projects" onClick={() => setPlace("projects")}>
            <li className={`item ${place === "projects" ? "visited" : ""}`}>
              {navItems[2]}
            </li>
          </Link>
          <Link
            to="service-request"
            onClick={() => setPlace("service-request")}
          >
            <li
              className={`item ${place === "service-request" ? "visited" : ""}`}
            >
              {navItems[4]}
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
