import { Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import React from 'react';
import Services from './pages/services/Services';
import Projects from './pages/projects/Projects';
import NotFound from './components/notfound/NotFound';
import ServiceRequest from './pages/service-request/ServiceRequest';

const AppRoutes = () => {
    return (
        <Routes basename="/">
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/main" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/service-request" element={<ServiceRequest />} />
        </Routes>
    );
}

export default AppRoutes;
