import React, { useContext, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { LangContext } from "../../context/LanguageProvider";
import { translations } from "./translate";
import { useDispatch, useSelector } from "react-redux";
import { AddContactAction } from "../../redux/actions/ContactActions";
import { GetAboutUsAction } from "../../redux/actions/AboutUsAction";
import { toast } from "react-toastify";
import "./Footer.scss";

const Footer = () => {
  const [userInfo, setUserInfo] = useState([]);
  const { aboutUs } = useSelector((state) => state.aboutUs);
  const { loading } = useSelector((state) => state.contacts);
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(userInfo);
    let formData = new FormData();
    formData.append("email", userInfo.email);
    formData.append("message", userInfo.description);
    formData.append("name", userInfo.name);

    dispatch(AddContactAction(formData))
      .then(({ payload }) => {
        // console.log(payload);
        if (payload?.data) {
          toast.success(payload.message);
        } else {
          toast.error(payload.message);
          // showError(payload?.message, toast);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  useEffect(() => {
    dispatch(GetAboutUsAction());
  }, [dispatch]);

  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <h3>
          MR.APPS <img src="./qrrrD.png" alt="MR.APPS" />
        </h3>
        <div className="footer-links">
          <a
            href={aboutUs.facebook}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/facebook.png")}
              alt="facebook-icon"
            />
          </a>
          <a
            href={aboutUs.instagram}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/instagram.png")}
              alt="instagram-icon"
            />
          </a>
          <a href={`mailto:${aboutUs.email}`} className="social-icon">
            <img
              src={require("./../../assets/images/social-media/mail.png")}
              alt="mail-icon"
            />
          </a>
          <a
            href={aboutUs.linkedIn}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/linkedin.png")}
              alt="linkedin-icon"
            />
          </a>
        </div>
        <div className="footer-text">© 2024 MR.APPS All rights reserved.</div>
      </div>
      <div className="footer-right">
        <h3>{translatedData.contactUs}</h3>
        <form onSubmit={submitHandler}>
          <InputText
            required
            onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
            unstyled
            className="custom-input"
            type="text"
            placeholder={translatedData.fullNamePlaceholder}
          />
          <InputText
            required
            onChange={(e) =>
              setUserInfo({ ...userInfo, email: e.target.value })
            }
            unstyled
            className="custom-input"
            type="email"
            placeholder={translatedData.emailPlaceholder}
          />
          <InputTextarea
            maxLength={500}
            required
            onChange={(e) =>
              setUserInfo({ ...userInfo, description: e.target.value })
            }
            rows={5}
            className="custom-input"
            id="address"
            autoResize
            placeholder={translatedData.descriptionPlaceholder}
          />
          <div className="submit">
            <Button
              className="logo"
              loading={loading}
              label={translatedData.sendButtonLabel}
              type="submit"
              size="small"
              aria-label="Submit"
            />
          </div>
        </form>
      </div>
    </footer>
  );
};

export default Footer;
