import React from "react";
import AppRoutes from "./routes";
import './assets/styles/global.scss'
import { BrowserRouter } from "react-router-dom";
import { LanguageProvider } from "./context/LanguageProvider";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <div className="body-container ">
          <Navbar />
          <ToastContainer />
          <AppRoutes />
          <Footer />
        </div>
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;
