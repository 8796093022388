import React, { useContext } from "react";
import "./Landing.scss";
import { useSelector } from "react-redux";
import { LangContext } from "../../../context/LanguageProvider";
import Slider from "../../../components/slider/Slider";

const Landing = () => {
  const { aboutUs } = useSelector((state) => state.aboutUs);
  const { lang } = useContext(LangContext);

  return (
    <div className="banner">
      <div className="banner-container transparent">
        <h2>MR.APPS</h2>
        <div className="bio">
          <Slider />
          <p className="desc">
            {lang === "en"
              ? aboutUs.main_description
              : aboutUs.main_description_ar}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
