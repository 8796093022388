import React, { useContext, useEffect } from "react";
import ProjectCard from "../../components/projeect-card/ProjectCard";
import LoadingFS from "../../components/loading/LoadingFS";
import { useDispatch, useSelector } from "react-redux";
import { GetProjectsAction } from "../../redux/actions/ProjectAction";
import "./Projects.scss";
import { imageURL } from "../../redux/Api";
import { LangContext } from "../../context/LanguageProvider";

const Projects = () => {
  const { loading, projects } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const { lang } = useContext(LangContext);

  useEffect(() => {
    dispatch(GetProjectsAction({ page: 1, size: 100 }));
  }, [dispatch]);
  return (
    <div className="projects">
      {loading && <LoadingFS />}
      <div className="grid">
        {projects.map((item, index) => {
          return (
            <ProjectCard
              link={item.URL}
              image={imageURL + item.imagePath}
              key={index}
              title={lang === "en" ? item.title_en : item.title_ar}
              desc={lang === "en" ? item.description_en : item.description_ar}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
