import React, { useContext, useEffect, useState } from "react";
import "./ServiceRequest.scss";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { GetServicesAction } from "../../redux/actions/ServiceAction";
import { useDispatch, useSelector } from "react-redux";
import { imageURL } from "../../redux/Api";
import LoadingFS from "../../components/loading/LoadingFS";
import { translations } from "./translate";
import { LangContext } from "../../context/LanguageProvider";
import { AddRequestAction } from "../../redux/actions/ContactActions";
import { toast } from "react-toastify";

const ServiceRequest = () => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];
  const [service, setService] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const { services } = useSelector((state) => state.services);
  const { loading } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetServicesAction({ page: 1, size: 100 }));
  }, [dispatch]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("email", email);
    formData.append("description", description);
    formData.append("name", name);
    formData.append("phone", phone);

    let info = {
      serviceId: service._id,
      obj: formData,
    };
    dispatch(AddRequestAction(info))
      .then(({ payload }) => {
        // console.log(payload);
        if (payload?.data) {
          toast.success(payload.message);
          setDescription("");
          setEmail("");
          setName("");
          setPhone("");
          setService("");
        } else {
          toast.error(payload?.message);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  return (
    <div className="service-request">
      {loading && <LoadingFS />}
      <form className="request-form transparent" onSubmit={handleSubmit}>
        <h2>{translatedData.header}</h2>
        <label>{translatedData.service}</label>
        <Dropdown
          appendTo={"self"}
          value={service}
          onChange={(e) => setService(e.value)}
          options={services}
          optionLabel={lang === "en" ? "title_en" : "title_ar"}
          itemTemplate={(option) => {
            return (
              <div className="service-label">
                <img alt={option.imagePath} src={imageURL + option.imagePath} />
                <div>{lang === "en" ? option.title_en : option.title_ar}</div>
              </div>
            );
          }}
          placeholder={translatedData.servicePlaceholder}
          className="custom-input"
        />

        <div className="form-group">
          <label htmlFor="name">{translatedData.name}:</label>
          <InputText
            value={name}
            required
            onChange={handleNameChange}
            unstyled
            className="custom-input"
            type="text"
            id="name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">{translatedData.email}:</label>
          <InputText
            value={email}
            required
            onChange={handleEmailChange}
            unstyled
            className="custom-input"
            type="email"
            id="email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">{translatedData.phone}:</label>
          <InputText
            value={phone}
            required
            onChange={handlePhoneChange}
            unstyled
            className="custom-input"
            type="tel"
            id="phone"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">{translatedData.description}:</label>
          <InputTextarea
            value={description}
            maxLength={500}
            required
            onChange={handleDescriptionChange}
            rows={5}
            className="custom-input"
            id="description"
            autoResize
          />
        </div>
        <div className="actions">
          <Button
            className="logo"
            loading={loading}
            label={"Submit"}
            type="submit"
            size="small"
            aria-label="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ServiceRequest;
