export const translations = {
  en: {
    nav: [
      "Main",
      "Services",
      "Projects",
      "En",
      "Request",
    ]
  },
  ar: {
    nav: [
      "رئيسي",
      "خدمات",
      "المشاريع",
      "عربي",
      "طلب"
    ]
  }
};
