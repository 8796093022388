import React from "react";

const ValueBox = ({ icon, title, desc }) => {
  return (
    <div className="box animate__animated">
      <div className="icon">
        <img src={icon} alt="title"></img>
      </div>
      <div className="title">{title}</div>
      <p className="desc">{desc}</p>
    </div>
  );
};

export default ValueBox;
