export const translations = {
    en: {
        header: "Service Request",
        service: "Select a service (Optional)",
        servicePlaceholder: "Select a service",
        name: "Name",
        email: "Email",
        phone: "Phone",
        description: "Description",
        sendButtonLabel: "Send",
    },
    ar: {
        header: "طلب خدمة",
        service: "اختار خدمة (اختياري)",
        servicePlaceholder: "اختار خدمة",
        name: "الاسم",
        email: "البريد الإلكتروني",
        phone: "رقم المحمول",
        description: "وصف الطلب",
        sendButtonLabel: "إرسال",
    }
};
