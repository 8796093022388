export const baseURL = 'https://it.mr-appss.com/api/';
export const imageURL = 'https://it.mr-appss.com/';

//Services
export const GetServicesAPI = `${baseURL}services/get?page=`

//Projects
export const GetProjectsAPI = `${baseURL}projects/get?page=`

//Contact
export const AddContactAPI = `${baseURL}contacts/add`

//About Us
export const GetAboutUsAPI = `${baseURL}aboutUs/get`

//Request
export const PostRequestAPI = `${baseURL}requests/add`
