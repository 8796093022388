import SupportIcon from "../../../assets/images/values/Support.png";
import QualityIcon from "../../../assets/images/values/Quality.png";
import InteractionIcon from "../../../assets/images/values/Interaction.png";
import IdeasIcon from "../../../assets/images/values/Interaction.png";
import DesignIcon from "../../../assets/images/values/Ideas.png";
import ReliabilityIcon from "../../../assets/images/values/Design.png";

export const translations = {
    en: {
        title: "Why are our services at MR.APPS the best?",
        desc: "Enjoy many of the features that we offer at MR.APPS, including speed, beautiful design, and SEO.",
        values: [
            {
                icon: SupportIcon,
                title: "Support",
                desc: "We provide MR.APPS customers with constant support, and we never skimp on assistance and suggestions.",
            },
            {
                icon: QualityIcon,
                title: "Quality",
                desc: "We depend on the best software solutions and use smart technology.",
            },
            {
                icon: InteractionIcon,
                title: "Interaction",
                desc: "We take all the client's ideas and perceptions and turn them into reality, adding our development and experience to them.",
            },
            {
                icon: IdeasIcon,
                title: "Ideas",
                desc: "MR.APPS has a young, ambitious team full of creative ideas that increase the confidence of our customers.",
            },
            {
                icon: DesignIcon,
                title: "Design",
                desc: "Due to the importance of design, we combine purpose, comfort, speed, and aesthetic aspects in our designs.",
            },
            {
                icon: ReliabilityIcon,
                title: "Reliability",
                desc: "Trust between us and our customers is first and foremost, and this is why we attribute our success.",
            },
        ],
    },
    ar: {
        title: "لماذا خدماتنا في MR.APPS هي الأفضل؟",
        desc: "استمتع بالعديد من الميزات التي نقدمها في MR.APPS ، بما في ذلك السرعة والتصميم الجميل وتحسين محركات البحث.",
        values: [
            {
                icon: SupportIcon,
                title: "الدعم",
                desc: "نقدم لعملائنا في MR.APPS الدعم الدائم، ونحن لا نتهاون في المساعدة والاقتراحات.",
            },
            {
                icon: QualityIcon,
                title: "الجودة",
                desc: "نعتمد على أفضل الحلول البرمجية ونستخدم التكنولوجيا الذكية.",
            },
            {
                icon: InteractionIcon,
                title: "التفاعل",
                desc: "نأخذ جميع أفكار وتصورات العميل ونحولها إلى واقع، مضيفين تطويرنا وخبراتنا إليها.",
            },
            {
                icon: IdeasIcon,
                title: "الأفكار",
                desc: "MR.APPS لديها فريق شاب وطموح مليء بأفكار إبداعية تزيد من ثقة عملائنا.",
            },
            {
                icon: DesignIcon,
                title: "التصميم",
                desc: "نظرًا لأهمية التصميم، نجمع بين الغرض والراحة والسرعة والجوانب الجمالية في تصاميمنا.",
            },
            {
                icon: ReliabilityIcon,
                title: "الموثوقية",
                desc: "الثقة بيننا وبين عملائنا في المقام الأول، وهذا هو السبب في أننا نعزو نجاحنا إليها.",
            },
        ],
    },
};
