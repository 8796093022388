import { createSlice } from '@reduxjs/toolkit';
import { GetProjectsAction } from '../actions/ProjectAction';
export const projectReducer = createSlice({
    name: "Project Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        projects: [],
    },
    reducers: {
        projectsUnmount: (state) => {
            state.projects = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetProjectsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetProjectsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.projects = payload.data.projects;
                state.totalItems = payload.data.totalItems;
                state.currentPage = payload.data.currentPage;
            })
            .addCase(GetProjectsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default projectReducer.reducer;
export const { projectsUnmount } = projectReducer.actions;
